<template>
  <el-dialog
    center
    title="系统请求"
    width="40%"
    :modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
  >
    <div id="dk-question-feedback-modal-content">
      <!-- <el-descriptions title="基本信息" :column="2" size="medium" border style="margin-bottom: 20px;">
        <el-descriptions-item>
          <template slot="label">页面</template>
          {{ pageName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">操作时间</template>
          {{ currentTime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">用户</template>
          {{ userInfo.nick }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">用户id</template>
          {{ userInfo.huid }}
        </el-descriptions-item>
      </el-descriptions> -->

      <div style="font-size: 16px;font-weight: 700;color:#303133;">最近5条请求 ({{ pageName }} {{ userInfo.nick }} {{ userInfo.huid }})</div>
      <el-table :data="tableData" max-height="500" style="width: 100%">
        <el-table-column prop="url" label="接口" width="180">
        </el-table-column>
        <el-table-column prop="method" label="请求类型" width="80">
        </el-table-column>
        <el-table-column prop="status" label="请求状态" width="150">
          <template slot-scope="scope">
            <el-tag :type="tagType(scope.row.status)">{{ statusMapp[scope.row.status] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="traceId" label="问题Id">
          <template slot-scope="scope">
            <span style="color: #5a9cf8;cursor: pointer;" @click="copyToClipboard(scope.row.traceId)">{{ scope.row.traceId }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="requestTime" label="请求时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.requestTime }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="copyAllToClipboard">复 制</el-button>
    </span>
  </el-dialog>
</template>

<script>
function getPageName(url) {
    const regex = /#([a-zA-Z0-9-]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
}
function getMenuText(href) {
    for (let category of window.esapp?.menuMap?.parentList) {
        for (let subMenu of category.subMenu) {
            if (subMenu.href === href) {
                return [category.text, subMenu.title];
            }
        }
    }
    for (let menu of window.esapp?.menuMap?.menuList) {
      if (menu.href === href) {
        return [menu.title]
      }
    }
    return ['工作台'];
}
function getModalTitle() {
    const modalElement = document.querySelector('div.dk-modal.fade.in'); // 获取 modal 元素
    if (modalElement) {
        const titleElement = modalElement.querySelector('.modal-title'); // 获取 modal-title 元素
        if (titleElement) {
            return titleElement.textContent
        } else {
            return ''
        }
    } else {
        return ''
    }
}


export default {
  data() {
    return {
      dialogVisible: false,
      userInfo: {},
      pageName: '',
      currentTime: '',
      statusMapp: {
        200: '200: 请求成功',
        401: '401: 没有登录',
        500: '500: 服务器错误',
      },
      tableData: []
    };
  },
  created() {
    this.interceptRequests();

    this.userInfo = JSON.parse(localStorage.getItem('iam-user-info'))

    window.__interceptRequests = (name) => {
      for (let item = 0; item < 100; item++) {
        if (window[item] === undefined) {break;}
        if (name === window[item].name) {
          this.interceptRequests(window[item])
        }
      }
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.currentTime = dayjs().format('YYYY-MM-DD hh:mm:ss')
      this.pageName = this.getPageName()
    },
    getPageName() {
      const pageName = getPageName(location.hash)
      const menulist = getMenuText(pageName)
      const modalTitle = getModalTitle()
      if (modalTitle) { menulist.push(modalTitle) }

      return menulist.join('/')
    },
    tagType(status) {
      if (status >= 200 && status < 300) {
        return 'success';
      } else if (status >= 400 && status < 500) {
        return 'danger';
      } else {
        return 'warning';
      }
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    handleClose(done) {
      done();
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => {});
    },
    copyAllToClipboard() {
//       const basicInfo = `
// 页面: \t${this.pageName}\t
// 操作时间: \t${this.currentTime}
// ${this.userInfo.nick}\t (${this.userInfo.huid})
// `;
const basicInfo = `最近5条请求 (${ this.pageName } ${ this.userInfo.nick } ${ this.userInfo.huid })
`;
const requestList = this.tableData.map(item => `
接口：${item.url}\t ${ item.status >= 300 ? ('(' + this.statusMapp[item.status] + ')') : '' }
问题Id：${item.traceId}`).join('\n');

      

      const fullText = basicInfo + requestList;

      const input = document.createElement('textarea');
      input.value = fullText;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      this.$message.success('复制成功');
    },
    copyToClipboard(text) {
      const input = document.createElement('input');
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      this.$message.success('复制成功');
    },
    interceptRequests(currentWindow = window) {
      const originalFetch = currentWindow.fetch;
      currentWindow.fetch = async (...args) => {
        const response = await originalFetch(...args);
        const { url, method } = args[1] || { method: 'GET' };
        if ((method === 'GET' || method === 'POST') && url && !url?.match(/\.(html|json|js|tpl|css)$/)) {
          const traceId = response.headers.get('X-Trace-Id');
          const requestTime = new Date().toLocaleString();
          url && this.addRequestToTable({ url: url.split('?')[0], method, status: response.status, traceId, requestTime });
        }
        return response;
      };

      const self = this;
      const originalXHROpen = currentWindow.XMLHttpRequest.prototype.open;
      currentWindow.XMLHttpRequest.prototype.open = function (method, url, ...rest) {
        this.addEventListener('load', function () {
          if ((method === 'GET' || method === 'POST') && url && !url?.match(/\.(html|json|js|tpl|css)$/)) {
            const traceId = this.getResponseHeader('X-Trace-Id');
            const requestTime = new Date().toLocaleString();
            self.addRequestToTable({ url: url.split('?')[0], method, status: this.status, traceId, requestTime });
          }
        });
        originalXHROpen.call(this, method, url, ...rest);
      };
    },
    addRequestToTable(request) {
      this.tableData.unshift(request);
      if (this.tableData.length > 5) {
        this.tableData = this.tableData.slice(0, 5);
      }
    }
  }
};
</script>
