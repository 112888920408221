var Vue = require('vue');
var responseModal = require('dk-response-modal')
var dkLocaleSelect = require('dk-locale-select')
var dkQuestionFeedbackModal = require('dk-question-feedback-modal')

setTimeout(() => {
var vm = new Vue({
    el: '#nav',
    components: {
        'dk-response': responseModal.default,
        'dk-locale-select': dkLocaleSelect.default,
        'dk-question-feedback-modal': dkQuestionFeedbackModal.default
    },
    data: {
        showThisWrap:false,
        hasAuth: false,
        messageCount: 0,
        responseModal:{
            fade: false,
            title: '客户',
            info: {},
            config: {},
            id: '',
            oppositeId: '',
            count: 0
        },
        timer: ''
    },
    mounted: function(){
        var that = this
        var timer = setInterval(function(){
            if(!esapp.user){
                return
            }else{
                clearInterval(timer)
                esapp.user.authorities.forEach(function(v){
                    if(v == 'CUSTOMER_COMMUNICATE'){
                        that.hasAuth = true
                        that.getMessageCount()
                        if(window.location.host.indexOf("localhost")>=0){
                            //是本地开发的话，就不让开启 WebSocket 了
                            return;
                        }
                        that.init()
                        return
                    }
                })
            }
        },100)
    },
    methods: {
        hideThisWrap:function (params) {
            this.showThisWrap = false
        },
        getwebsocketurl:function(){
            // return 'ws://192.168.30.185:8080/websocket'
            // return 'ws://qa.new.carrierglobe.com/websocket'
            
            var host = window.location.host
            var protocol = (host.indexOf('qa') != -1) ?'wss://' :'wss://'
            return protocol + host + '/websocket'
        },
        init: function(){

            if(this.timer) clearInterval(this.timer)

        },
        onQuestionFeedback() {
            this.$refs.dkQuestionFeedbackModalRef.open()
        },
        getMessageCount: function(val){
            var that = this
            if(!val) {
                _.util.ajax_get('/messenger/waiting/dispose/messageCount',{},function(res){
                    that.messageCount = res.data.value
                    if(that.messageCount){
                        that.showThisWrap = true
                        console.log( that.showThisWrap)
                    }
                    // that.setNotice(that.messageCount)
                })
            }
            _.util.ajax_get('/messenger/waiting/dispose/messageInfo',{},function(res){
                console.log(res)
                if(!res.data) return
                res = eval('(' + res.data.value + ')')
                that.responseModal.title = res.createdByName + '--' + res.companyName
                that.responseModal.id = res.earliestPostingId
                that.responseModal.oppositeId = res.createdBy
                that.responseModal.count = res.count
                if(that.messageCount){
                    that.showThisWrap = true
                    console.log( that.showThisWrap)
                }
            })
        },
        queryMessage: function(){
            if(window.location.hash.indexOf('customer-communication') != -1){
                $('[esapp-id="customer-communication-query"]')[0].click()
            }else{
                window.location.href = '#customer-communication?&1'
            }
        },
        setNotice: function(i){
            if(i == 0) return
            if (window.Notification) {                
                var popNotice = function() {
                    if (Notification.permission == "granted") {
                        var notification = new Notification("Hi，帅哥：", {
                            body: '有你的新消息~【'+i+'】',
                            icon: '//image.zhangxinxu.com/image/study/s/s128/mm1.jpg',
                            tag: 'customerMessage',
                            renotify: true
                        });
                        
                        notification.onclick = function() {
                            notification.close();    
                        };
                    }    
                };
                
                if (Notification.permission == "granted") {
                    popNotice();
                } else if (Notification.permission != "denied") {
                    Notification.requestPermission(function (permission) {
                      popNotice();
                    });
                }
            }
        },
        response: function(item,addContent){
            // this.responseModal.title = 'zhh--上海管易云计算软件有限公司';
            this.responseModal.fade = true;
            this.responseModal.config = {
                id: this.responseModal.id,
                key: 'customer',
                documentId: 'customer-communication',
                editorTool: [
                    'image',
                    'file'
                ],
                // changeInfo: item.id ?true :false,
                buttons: [
                    'complete',
                    'containerOrder',
                    'send'
                ],
                oppositeId: this.responseModal.oppositeId,
            }
        },
        completeChat: function() {
            this.responseModal.fade = false;
            this.getMessageCount();
            if(window.location.hash.indexOf('customer-communication') != -1){
                $('[esapp-id="customer-communication-query"]')[0].click()
            }
        }
    }
})
}, 2500);